import React, { useEffect, useState } from 'react';
import { Wrapper, ButtonWrapper, ARButtonWrapper } from './player.styles';
import { Button, Modal } from 'antd';
import MobileDetect from 'mobile-detect';

const Player = () => {
  // Not sure why this init is also here. The configurator already inits in
  // AppProvider's componentDidMount (context.js)
  // useEffect(() => {
  //   (() => {
  //     console.log(window.threekit);
  //     window.threekit.init({ el: 'player-root' });
  //   })();
  // });

  const [selectItem, setSelectItem] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [device] = useState(new MobileDetect(window.navigator.userAgent).os());

  const handleSelectChange = (e) => {
    const id = e.detail.id;
    const selectItem = id && window.threekit.configurator.getItemById(id);
    setSelectItem(selectItem);
  };

  useEffect(() => {
    window.addEventListener('itemSelect', handleSelectChange);
    return () => {
      window.removeEventListener('itemSelect', handleSelectChange);
    };
  }, []);

  const handleSaveClick = async () => {
    window.threekit.configurator.hidePlusSign();
    await new Promise(async (resolve) => {
      await window.threekit.api.player.evaluateSceneGraph();
      resolve();
    });

    const resultId = await window.threekit.configurator.saveConfiguration();
    const snapshotStr = await window.threekit.api.snapshotAsync();

    // Convert base64 string to image and download
    if (snapshotStr && resultId) {
      // Create a link element
      const link = document.createElement('a');
      // Set the href to a data URL with the base64 string
      // The base64 string might already include the data URL prefix, so check first
      const dataUrl = snapshotStr.startsWith('data:')
        ? snapshotStr
        : `data:image/png;base64,${snapshotStr}`;
      link.href = dataUrl;
      // Set the download attribute to the filename (resultId)
      link.download = `${resultId}.png`;
      // Append to body
      document.body.appendChild(link);
      // Trigger a click on the link
      link.click();
      // Remove the link
      document.body.removeChild(link);
    }

    console.log(resultId);
  };

  const handleARClick = async () => {
    const result = await window.threekit.configurator.getAR();

    const link = document.createElement('a');
    if (device === 'iOS') {
      const { url } = await result.usdz;
      link.rel = 'ar';
      link.href = url;

      link.click();
      window.URL.revokeObjectURL(url);
    } else if (device === 'AndroidOS') {
      const { url } = await result.glb;
      const href = `intent://arvr.google.com/scene-viewer/1.0?file=${url}/model.glb&mode=ar_preferred#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${url};end;`;
      link.href = href;

      link.click();
      window.URL.revokeObjectURL(url);
    } else {
      const { dataURL } = result;
      Modal.info({
        title: 'Scan QR Code',
        content: (
          <div>
            <img src={dataURL} alt=""></img>
          </div>
        ),
        onOk: () => {},
      });
    }
  };

  return (
    <Wrapper>
      <div id="player-root" />
      <ARButtonWrapper>
        <Button onClick={handleSaveClick} style={{ marginRight: '10px' }}>
          Save
        </Button>
        <Button onClick={handleARClick}>AR</Button>
      </ARButtonWrapper>
      {selectItem && (
        <ButtonWrapper>
          <Button onClick={() => window.threekit.configurator.deleteItem()}>
            Delete Item
          </Button>
          <Button
            onClick={window.threekit.configurator.incrementSelectItemRotation}
          >
            Rotate Item
          </Button>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export default Player;
